const { url } = require('./config');

const tournament_api = {

    async getTournaments () {
        const response = await fetch(url + '/tournaments/t');
        const json = await response.json();
        return json;
    },

    async getTournamentById (id) {
        if (localStorage.username !== undefined) {
            const response = await fetch(url + '/tournaments/t/' + id, {
                headers: {
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password),
                }
            });
            return await response.json();
        }
        const response = await fetch(url + '/tournaments/t/' + id);
        return await response.json();
    },

    async addPlayerToTournament (id, player) {
        const response = await fetch(url + '/tournaments/t/' + id + '/p', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(player),
        });
        if (response.status === 204) return { text: 'Inscription réussie !', status: response.status };
        return { text: await response.json(), status: response.status };
    },

    async deletePlayerFromTournament (id, player) {
        const response = await fetch(url + '/tournaments/t/' + id + '/p/' + player, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password),
            },
        });
        return response.status;
    },

    async updatePlayerFromTournament (id, player) {
        const response = await fetch(url + '/tournaments/t/' + id + '/p/' + player.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password),
            },
            body: JSON.stringify(player),
        });
        return response.status;
    },

    async getCourtsByTournamentId (id) {
        const response = await fetch(url + '/tournaments/t/' + id + '/c');
        const json = await response.json();
        return json;
    },

    async getMatchesByTournamentId (id) {
        const response = await fetch(url + '/tournaments/t/' + id + '/g');
        const json = await response.json();
        return json;
    },

    async getPlayersByTournamentId (id) {
        const response = await fetch(url + '/tournaments/t/' + id + '/p');
        const json = await response.json();
        return json;
    },

    async startGameByTournamentId (id, gid, game) {
        const response = await fetch(url + '/tournaments/t/' + id + '/g/' + gid + '/start', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
            },
            body: JSON.stringify(game),
        });
        return response.status;
    },

    async setGameResultByTournamentId (id, gid, scores) {
        const response = await fetch(url + '/tournaments/t/' + id + '/g/' + gid + '/score', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
            },
            body: JSON.stringify(scores),
        });
        return response.status;
    },

    async generateTournamentGamesByTournamentId (id) {
        const response = await fetch(url + '/tournaments/t/' + id, {
            method: 'POST',
            headers: {
                'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
            }
        });
        return response.status;
    },

    async createTournament (tournament) {
        const response = await fetch(url + '/tournaments/t', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password),
            },
            body: JSON.stringify(tournament),
        });
        return response.status;
    },

    async updateTournament (id, tournament) {
        const response = await fetch(url + '/tournaments/t/' + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password),
            },
            body: JSON.stringify(tournament),
        });
        return response.status;
    }
}

export { tournament_api };