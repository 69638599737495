import {Helmet} from "react-helmet";
import React, {useEffect, useState} from 'react';
import {tournament_api} from "../api/tournament_api";
import TournamentDisplay from "../components/tournaments/TournamentDisplay";
import TournamentCreateForm from "../components/tournaments/TournamentCreateForm";

function Tournaments () {

    const [tournaments, setTournaments] = useState([]);
    const tournamentsDisplay = tournaments.reverse().map((tournament) =>
        <TournamentDisplay key={tournament.id} tournament = {tournament}/>
    );

    useEffect(() => {
        async function fetchData() {
            const resp = await tournament_api.getTournaments();
            setTournaments(resp);
        }
        fetchData();
    }, []);

    return (
        <div className="mt-6 mb-10">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{"ASFlavigny - Tournois"}</title>
                <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                <script type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-GLK66L6C6D');
                    `}
                </script>
            </Helmet>

            <div className="space-y-5">
                {tournamentsDisplay}
            </div>

            {(localStorage.getItem('username') !== null) && <div className="bg-red-200 w-full rounded p-2 mt-10">
                <h1 className="font-bold text-xl">Création d'un tournoi</h1>
                <div className="flex space-x-3">
                    <TournamentCreateForm />
                </div>
            </div>}
        </div>
    );
}

export default Tournaments;