import React, {useEffect, useState} from 'react';
import {tournament_api} from "../../api/tournament_api";
import {useInput} from "../../hooks/input-hook";

function MatchDisplay ({match, players, courts, tournament_id}) {

    const { value:court, bind:bindCourt, reset:resetCourt } = useInput(0);

    for (let i = 0; i < players.length; i++) {
        if (players[i].id === match.player1_id) {
            match.player1 = players[i];
        }
        if (players[i].id === match.player2_id) {
            match.player2 = players[i];
        }
        if (match.player3 !== null && players[i].id === match.player3_id) {
            match.player3 = players[i];
        }
        if (match.player4 !== null && players[i].id === match.player4_id) {
            match.player4 = players[i];
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        if (court === 0) {
            resetCourt();
            alert("Veuillez choisir un terrain !");
            return;
        }

        // check if court is available
        for (let i = 0; i < courts.length; i++) {
            if (courts[i].id === court - 1) {
                if (courts[i].game !== null) {
                    resetCourt();
                    alert("Terrain déjà occupé !");
                    return;
                }
            }
        }

        const data = {
            court: court,
            effective_time: new Date().toISOString().slice(0, 19).replace('T', ' '),
        }

        try {
            let result = await tournament_api.startGameByTournamentId(tournament_id, match.id, data);
            if (result === 204) {
                alert("Match commencé !");
            } else {
                alert("Erreur lors du démarrage du match !");
            }
        } catch (e) {
            console.log(e);
        }

        setTimeout(() => {
            window.location.reload();
        }, 2000);
        return false;
    }

    return (
        <div>
            {(match.player1) && <div className="drop-shadow">
                <div className="flex justify-between bg-gray-50 rounded">
                    <div className="flex">
                        <div className="bg-flavigny text-white font-bold px-1 md:py-1 md:px-3 rounded-tl">
                            N° { match.custom_id + 1 }
                        </div>
                        <div className="bg-blue-300 px-1 md:py-1 md:px-3 rounded-br">
                        <span className="font-bold">
                            { match.time_start.substring(0, 5) }
                        </span>
                        </div>
                    </div>
                    <div className={match.court === 0 ? "bg-gray-300 text-white px-1 md:py-1 md:px-3 rounded-tr rounded-bl" : "bg-flavigny text-white px-1 md:py-1 md:px-3 rounded-tr rounded-bl"}>
                    <span>
                        T
                    </span>
                        <span className="font-bold">
                        { match.court }
                    </span>
                    </div>
                </div>
                <div className="bg-gray-50 rounded-b p-5">
                    <div className="flex justify-between items-center gap-4">
                        <div style={{fontWeight: match.ended && match.player1_win ? 'bold' : 'normal'}}>
                            <div>
                                { match.player1.firstname.charAt(0).toUpperCase() + match.player1.firstname.slice(1) } { match.player1.lastname.charAt(0).toUpperCase() + match.player1.lastname.slice(1) }
                            </div>
                            {(match.player3) && <div>
                                { match.player3.firstname.charAt(0).toUpperCase() + match.player3.firstname.slice(1) } { match.player3.lastname.charAt(0).toUpperCase() + match.player3.lastname.slice(1) }
                            </div>}
                        </div>
                        {(!match.ended) && <div className="text-xl font-bold text-gray-400">
                            VS
                        </div>}
                        {(match.ended) && <div className="whitespace-nowrap text-sm md:text-xl font-bold text-green-400">
                            <div>{match.score1}</div>
                            <div>{match.score2}</div>
                            <div>{match.score3}</div>
                        </div>}
                        <div className="text-right" style={{fontWeight: match.ended && !match.player1_win ? 'bold' : 'normal'}}>
                            <div>
                                { match.player2.firstname.charAt(0).toUpperCase() + match.player2.firstname.slice(1) } { match.player2.lastname.charAt(0).toUpperCase() + match.player2.lastname.slice(1) }
                            </div>
                            {(match.player4) && <div>
                                { match.player4.firstname.charAt(0).toUpperCase() + match.player4.firstname.slice(1) } { match.player4.lastname.charAt(0).toUpperCase() + match.player4.lastname.slice(1) }
                            </div>}
                        </div>
                    </div>
                </div>
            </div>}
            {(localStorage.getItem('username') !== null && match.court === 0 && !match.ended) &&
                <div className="bg-gray-100 p-2">
                    <form onSubmit={handleSubmit}>
                        <select {...bindCourt}>
                            <option value={0}>Choisir un terrain</option>
                            {Array.from(courts).map((court, index) => (
                                <option key={index} value={index + 1}>Terrain {index + 1}</option>
                            ))}
                        </select>
                        <button type="submit" className="font-bold px-3">
                            Valider
                        </button> (Cette action débutera le match)
                    </form>
                </div>
            }
        </div>
    );
}

export default MatchDisplay;