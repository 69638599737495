import React, {useEffect, useRef, useState} from 'react';

function Photo (props) {

    return(
        <div className={"p-3"}>
            <img className={"max-w-screenEight max-h-screenEight"} src={"/content" + props.img} alt={"Photo de l'album " + props.album}/>
        </div>
    );
}

export default Photo;