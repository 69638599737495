function isPlayer1Winner (score1_1, score1_2, score2_1, score2_2, score3_1, score3_2) {
    let winner = 0;

    if (score1_1 > score1_2) {
        winner++;
    } else {
        winner--;
    }

    if (score2_1 > score2_2) {
        winner++;
    } else if (score2_1 < score2_2) {
        winner--;
    }

    if (winner !== 0) {
        return winner;
    } else {
        if (score3_1 > score3_2) {
            winner++;
        } else if (score3_1 < score3_2) {
            winner--;
        }
        return winner;
    }
}

function ScoreDisplay ({matches, players, tournament}) {

    const teams = [];

    if (tournament.type === "salade") {
        // for all matches, create another match with player1 and player2 inverted to create own team
        const matchesCopy = [...matches];
        for (let i = 0; i < matchesCopy.length; i++) {
            const match = matchesCopy[i];
            const matchCopy = {
                id: match.id,
                player1_id: match.player3_id,
                player1: match.player3,
                player2_id: match.player4_id,
                player2: match.player4,
                player3_id: match.player1_id,
                player3: match.player1,
                player4_id: match.player2_id,
                player4: match.player2,
                score1: match.score1,
                score2: match.score2,
                score3: match.score3,
                player1_win: match.player1_win,
            }
            matches.push(matchCopy);
        }
    }

    for (let i = 0; i < matches.length; i++) {

        // check if team not already in teams
        let team1 = undefined
        let team2 = undefined;
        team1 = teams.find(team => team.player1.id === matches[i].player1_id);
        team2 = teams.find(team => team.player1.id === matches[i].player2_id);

        if (team1 === undefined) {
            team1 = {
                player1: null,
                player2: null,
                score_global: 0,
                score_match: 0,
                score_set: 0,
                score_point: 0,
                total_match: 0,
                total_set: 0
            }
            for (let j = 0; j < players.length; j++) {
                if (matches[i].player1_id === players[j].id) {
                    team1.player1 = players[j];
                }
                if (tournament.type !== "salade")
                    if (matches[i].player3_id !== null && matches[i].player3_id === players[j].id) {
                        team1.player2 = players[j];
                    }
            }
            teams.push(team1);
        }
        if (team2 === undefined) {
            team2 = {
                player1: null,
                player2: null,
                score_global: 0,
                score_match: 0,
                score_set: 0,
                score_point: 0,
                total_match: 0,
                total_set: 0
            }
            for (let j = 0; j < players.length; j++) {
                if (matches[i].player2_id === players[j].id) {
                    team2.player1 = players[j];
                }
                if (tournament.type !== "salade")
                    if (matches[i].player4_id !== null && matches[i].player4_id === players[j].id) {
                        team2.player2 = players[j];
                    }
            }
            teams.push(team2);
        }

        let score1 = {
            team1: 0,
            team2: 0
        }
        matches[i].score1.split(" - ").forEach((score, index) => {
            if (index === 0) {
                score1.team1 = Math.abs(parseInt(score));
            } else {
                score1.team2 = Math.abs(parseInt(score));
            }
        });
        let score2 = {
            team1: 0,
            team2: 0
        }
        matches[i].score2.split(" - ").forEach((score, index) => {
            if (index === 0) {
                score2.team1 = Math.abs(parseInt(score));
            } else {
                score2.team2 = Math.abs(parseInt(score));
            }
        });
        let score3 = {
            team1: 0,
            team2: 0
        }
        if (matches[i].score3 !== null) {
            matches[i].score3.split(" - ").forEach((score, index) => {
                if (index === 0) {
                    score3.team1 = Math.abs(parseInt(score));
                } else {
                    score3.team2 = Math.abs(parseInt(score));
                }
            });
        }


        const RESULT_WIN = 1;
        const RESULT_LOSE = -1;


        let result = isPlayer1Winner(score1.team1, score1.team2, score2.team1, score2.team2, score3.team1, score3.team2);


        if (result >= RESULT_WIN) {
            team1.score_global += 2;
            team1.score_match++;
            team1.score_set += 2;
            if (score1.team1 > score1.team2) {
                team1.score_point += Math.abs(score1.team1 - score1.team2);
                team2.score_point -= Math.abs(score1.team2 - score1.team1);
            } else {
                team1.score_point -= Math.abs(score1.team1 - score1.team2);
                team2.score_point += Math.abs(score1.team2 - score1.team1);
            }
            if (matches[i].score2 !== null) {
                if (score2.team1 > score2.team2) {
                    team1.score_point += Math.abs(score2.team1 - score2.team2);
                    team2.score_point -= Math.abs(score2.team2 - score2.team1);
                } else {
                    team1.score_point -= Math.abs(score2.team1 - score2.team2);
                    team2.score_point += Math.abs(score2.team2 - score2.team1);
                }
            }
            if (matches[i].score3 !== null) {
                team1.score_set--;
                team1.score_point += Math.abs(score3.team1 - score3.team2);
                team2.score_set++;
                team2.score_point -= Math.abs(score3.team2 - score3.team1);
            }

        } else if (result <= RESULT_LOSE) {
            team2.score_global += 2;
            team2.score_match++;
            team2.score_set += 2;
            if (score1.team2 > score1.team1) {
                team2.score_point += Math.abs(score1.team2 - score1.team1);
                team1.score_point -= Math.abs(score1.team1 - score1.team2);
            } else {
                team2.score_point -= Math.abs(score1.team2 - score1.team1);
                team1.score_point += Math.abs(score1.team1 - score1.team2);
            }
            if (matches[i].score2 !== null) {
                if (score2.team2 > score2.team1) {
                    team2.score_point += Math.abs(score2.team2 - score2.team1);
                    team1.score_point -= Math.abs(score2.team1 - score2.team2);
                } else {
                    team2.score_point -= Math.abs(score2.team2 - score2.team1);
                    team1.score_point += Math.abs(score2.team1 - score2.team2);
                }
            }
            if (matches[i].score3 !== null) {
                team2.score_set--;
                team2.score_point += score3.team1 - score3.team2;
                team1.score_set++;
                team1.score_point += score3.team2 - score3.team1;
            }
        } else {
            team1.score_global++;
            team1.score_match++;
            team1.score_set++;
            team2.score_global++;
            team2.score_match++;
            team2.score_set++;
            if (score1.team1 > score1.team2) {
                team1.score_point += Math.abs(score1.team1 - score1.team2);
                team2.score_point -= Math.abs(score1.team2 - score1.team1);
            } else {
                team1.score_point -= Math.abs(score1.team1 - score1.team2);
                team2.score_point += Math.abs(score1.team2 - score1.team1);
            }
            if (matches[i].score2 !== null) {
                if (score2.team1 > score2.team2) {
                    team1.score_point += Math.abs(score2.team1 - score2.team2);
                    team2.score_point -= Math.abs(score2.team2 - score2.team1);
                } else {
                    team1.score_point -= Math.abs(score2.team1 - score2.team2);
                    team2.score_point += Math.abs(score2.team2 - score2.team1);
                }
            }
            if (matches[i].score3 !== null) {
                team1.score_point += Math.abs(score3.team1 - score3.team2);
                team2.score_point -= Math.abs(score3.team2 - score3.team1);
            }
        }
        team1.total_match++;
        team1.total_set += 2;
        team2.total_match++;
        team2.total_set += 2;
    }

    // sort teams by score_match desc and score_set desc
    teams.sort((a, b) => {
        if (a.score_global > b.score_global) {
            return -1;
        } else if (a.score_global < b.score_global) {
            return 1;
        } else {
            if (a.score_match / a.total_match > b.score_match / b.total_match) {
                return -1;
            } else if (a.score_match / a.total_match < b.score_match / b.total_match) {
                return 1;
            } else {
                if (a.score_set / a.total_set > b.score_set / b.total_set) {
                    return -1;
                } else if (a.score_set / a.total_set < b.score_set / b.total_set) {
                    return 1;
                } else {
                    if (a.score_point / a.total_match > b.score_point / b.total_match) {
                        return -1;
                    } else if (a.score_point / a.total_match < b.score_point / b.total_match) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }
        }
    });

    return (
        <div className="overflow-x-auto" style={{display: (matches.length) ? "block" : "none"}}>
            <h1 className="text-2xl font-medium text-gray-900">
                Classement
            </h1>
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                <thead className="ltr:text-left rtl:text-right">
                    <tr>
                        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Equipe</th>
                        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Score global</th>
                        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Matchs gagnés</th>
                        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Sets gagnés</th>
                        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Points gagnés</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {teams.map((team, index) => (
                        <tr key={index}>
                            <td className="whitespace-pre-wrap text-center px-4 py-2 font-medium text-gray-900">
                                {team.player1.firstname.charAt(0).toUpperCase() + team.player1.firstname.slice(1) + " " + team.player1.lastname.charAt(0).toUpperCase() + team.player1.lastname.slice(1)} {team.player2 !== null ? "\n" + team.player2.firstname.charAt(0).toUpperCase() + team.player2.firstname.slice(1) + " " + team.player2.lastname.charAt(0).toUpperCase() + team.player2.lastname.slice(1) : ""}
                            </td>
                            <td className="whitespace-nowrap text-center px-4 py-2 text-gray-700"><b>{team.score_global} / {team.total_match}</b> <span className={"text-xs"}>({(team.score_global / team.total_match).toFixed(2)})</span></td>
                            <td className="whitespace-nowrap text-center px-4 py-2 text-gray-700"><b>{team.score_match} / {team.total_match}</b> <span className={"text-xs"}>({(team.score_match / team.total_match).toFixed(2)})</span></td>
                            <td className="whitespace-nowrap text-center px-4 py-2 text-gray-700"><b>{team.score_set} / {team.total_set}</b> <span className={"text-xs"}>({(team.score_set / team.total_set).toFixed(2)})</span></td>
                            <td className="whitespace-nowrap text-center px-4 py-2 text-gray-700"><b>{team.score_point}</b> <span className={"text-xs"}>({(team.score_point / team.total_match).toFixed(2)})</span></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ScoreDisplay;