import React, {useEffect, useState} from 'react';
import {useInput} from "../../hooks/input-hook";
import Info from "../global/Info";
import {useRef} from "react";
import {announcements_api} from "../../api/announcements_api";

function AnnouncementForm() {

    const { value:content, bind:bindContent } = useInput('');
    const { value:link, bind:bindLink } = useInput('');
    const { value:linkText, bind:bindLinkText } = useInput('');

    const infoRef = useRef();

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        try {
            let result = await announcements_api.updateAnnouncement(1, content, link, linkText);
            if (result === 200) {
                infoRef.current.seeInfo("green", "Annonce créée !");
            } else {
                infoRef.current.seeInfo("red", "Erreur lors de la création de l'annonce !");
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="text-2xl text-center my-5">Ajouter une annonce</div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Contenu de l'annonce</span>
                        <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Un super nom..." {...bindContent} />
                    </label>
                </div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Texte du lien de l'annonce</span>
                        <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Texte du lien... (facultatif)" {...bindLinkText} />
                    </label>
                </div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Lien de l'annonce</span>
                        <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="https://... (facultatif)" {...bindLink} />
                    </label>
                </div>
                <div className="mb-5">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Ajouter l'annonce
                    </button>
                </div>
            </form>
            <div className={"mt-5"}>
                <Info ref={infoRef}/>
            </div>
        </div>
    );
}

export default AnnouncementForm;