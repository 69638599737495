import React, {useEffect, useState} from 'react';
import {useInput} from "../../hooks/input-hook";
import Info from "../global/Info";
import {useRef} from "react";
import {photo_api} from "../../api/photo_api";

// Component (here it's a view)
function DeletePhotoForm(props) {
    // Updatable variable
    const infoRef = useRef();

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        try {
            let result = await photo_api.deletePhoto(props.id);
            if (result === 204) {
                infoRef.current.seeInfo("green", "Photo supprimée !");
            } else {
                infoRef.current.seeInfo("red", "Erreur lors de la suppression de la photo !");
            }
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className={"p-5"}>
            <form onSubmit={handleSubmit}>
                <div className="mb-5">
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Supprimer la photo
                    </button>
                </div>
            </form>
            <div className={"mt-5"}>
                <Info ref={infoRef}/>
            </div>
        </div>
    );
}

export default DeletePhotoForm;