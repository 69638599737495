const { url } = require('./config');

const news_api = {

    // Fetch data...
    async getNews () {
        const response = await fetch(url + '/news/all');
        const json = await response.json();
        return json;
    },

    async getNewsWithLimit (limit) {
        const response = await fetch(url + '/news/all/' + limit);
        const json = await response.json();
        return json;
    },

    async getNewsWithLimitAndOffset (limit, offset) {
        const response = await fetch(url + '/news/all/' + limit + '/' + offset);
        const json = await response.json();
        return json;
    },

    async getPost (post) {
        const response = await fetch(url + '/news/post/' + post);
        const json = await response.json();
        return json;
    },

    async getLast () {
        const response = await fetch(url + '/news/last');
        const json = await response.json();
        return json;
    },

    async insertNews(title, content, image, minia, urlNews) {
        const response = await fetch(url + '/news',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "POST",
                body: JSON.stringify({
                    title: title,
                    content: content,
                    image: image,
                    minia: minia,
                    url: urlNews
                })
            });
        return response.status;
    },

    async deleteNews(id) {
        const response = await fetch(url + '/news/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "DELETE"
            });
        return response.status;
    },

    async updateNews(id, title, content, image, minia, urlNews) {
        const response = await fetch(url + '/news/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':"Basic " + window.btoa(localStorage.username + ':' + localStorage.password)
                },
                method: "PUT",
                body: JSON.stringify({
                    title: title,
                    content: content,
                    image: image,
                    minia: minia,
                    url: urlNews
                })
            });
        return response.status;
    }
}
export { news_api };