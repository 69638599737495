import React, {useEffect, useState} from 'react';

// Component (here it's a view)
function Header(props) {

    return (
        <div className={"mx-9"}>
            <div className={"max-w-lg my-0 mx-auto relative flex h-48 sm:h-96"}>
                <div className={"z-0 absolute right-0 bottom-0 w-full -mb-10 lg:mb-0 h-48 sm:h-[500px] bg-no-repeat bg-left-bottom bg-contain"} style={{backgroundImage: "url('/img/gymnase.png')"}}></div>
                <div className={"absolute bg-gradient-to-r from-flavigny to-green-600/0 w-1/2 h-48 sm:h-96"}></div>
                <div className={"z-10"}>
                    <h1 className={"pt-10 sm:pt-32 text-5xl sm:text-7xl text-white font-bold font-outfit"}>ASFlavigny</h1>
                    <h2 className={"text-2xl sm:text-4xl text-white font-outfit font-extralight"}>Association sportive de Badminton de Flavigny</h2>
                </div>
            </div>
        </div>
    )
}

export default Header;