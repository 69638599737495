import React, {useEffect, useRef, useState} from 'react';
import PopUp from "../global/PopUp";
import Video from "./Video";
import DeleteVideoForm from "../form/DeleteVideoForm";

function VideoProp (props) {

    const popUpRef = useRef();
    const popUpDel = useRef();

    return(
        <div>
            {
                localStorage.getItem('username') !== null &&
                <div className={"z-10 absolute m-2 bg-red-500 px-1 text-white rounded-full font-extrabold cursor-pointer"}
                     onClick={() => popUpDel.current.seePopup()}>SUPPRIMER</div>
            }
            <PopUp ref={popUpDel} children={<DeleteVideoForm id={props.video.id} />}/>
            <PopUp ref={popUpRef} children={<Video video={props.video} />}/>
            <div className={"bg-flavigny inline-block relative w-full cursor-pointer"} onClick={() => popUpRef.current.seePopup()}>
                <div style={{marginTop: '100%'}}></div>
                <div style={{top: '30%'}} className={"absolute left-0 right-0 bottom-0 text-center text-9xl text-white"}>
                    ?
                </div>
                <div className={"absolute top-0 left-0 right-0 bottom-0"}>
                    <img src={"/content" + props.video.minia} alt={"Video de l'album " + props.album} className="w-full h-full object-cover" />
                </div>
            </div>
        </div>
    );
}

export default VideoProp;