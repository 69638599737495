import React, {useEffect, useState} from 'react';
import {useInput} from "../../hooks/input-hook";
import Info from "../global/Info";
import {useRef} from "react";
import {photo_api} from "../../api/photo_api";

// Component (here it's a view)
function PhotoForm(props) {
    // Updatable variable
    const [selectedFile, setSelectedFile] = useState([]);

    const infoRef = useRef();

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const resizeImage = (base64Str) => {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.src = base64Str;
            var canvas = document.createElement('canvas');
            var MAX_WIDTH = 400;
            var MAX_HEIGHT = 400;
    
            img.onload = function() {
                var width = img.width;
                var height = img.height;
    
                if (width < height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL());
            }
    
            img.onerror = function() {
                reject('Failed to load image');
            }
        });
    }

    const cropImage = (base64Str) => {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.src = base64Str;
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            var MAX_WIDTH = 400;
            var MAX_HEIGHT = 400;
    
            img.onload = function() {
                var width = img.width;
                var height = img.height;
                var x = (width - MAX_WIDTH) / 2;
                var y = (height - MAX_HEIGHT) / 2;
    
                canvas.width = MAX_WIDTH;
                canvas.height = MAX_HEIGHT;
    
                // Dessiner l'image sur le canvas en la rognant au centre
                ctx.drawImage(img, x, y, MAX_WIDTH, MAX_HEIGHT, 0, 0, MAX_WIDTH, MAX_HEIGHT);

                resolve(canvas.toDataURL());
            }
    
            img.onerror = function() {
                reject('Failed to load image');
            }
        });
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        let numberOfPhotos = 0;
        for (let i = 0; i < selectedFile.length; i++) {
            const image = await toBase64(selectedFile[i]);
            const resize = await resizeImage(image);
            const minia = await cropImage(resize);
            // get the name of the file without the extension
            const name = selectedFile[i].name ? selectedFile[i].name.split('.')[0] : "default_name";
            const url = name.toLowerCase().replace(/ /g, '-');

            try {
                let result = await photo_api.insertPhoto(url, image, minia, props.album_id);
                if (result === 204) {
                    numberOfPhotos++;
                }
            } catch (e) {
                console.log(e);
            }
        }
        if (numberOfPhotos === 0) {
            infoRef.current.seeInfo("red", "Aucune photo ajoutée !");
        } else {
            infoRef.current.seeInfo("green", numberOfPhotos + " photos ajoutées !");
        }
    }
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="text-2xl text-center my-5">Ajouter une photo</div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Image</span>
                        <input type="file"
                               accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                               className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setSelectedFile(e.target.files)} multiple/>
                    </label>
                </div>
                <div className="mb-5">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Ajouter les photos
                    </button>
                </div>
            </form>
            <div className={"mt-5"}>
                <Info ref={infoRef}/>
            </div>
        </div>
    );
}

export default PhotoForm;