import {useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {tournament_api} from "../api/tournament_api";
import {Helmet} from "react-helmet";
import TournamentForm from "../components/tournaments/TournamentForm";
import CourtDisplay from "../components/tournaments/CourtDisplay";
import MatchDisplay from "../components/tournaments/MatchDisplay";
import TournamentUpdateForm from "../components/tournaments/TournamentUpdateForm";
import PlayerDisplay from "../components/tournaments/PlayerDisplay";
import ScoreDisplay from "../components/tournaments/ScoreDisplay";


function Tournament () {

    const { tournament } = useParams();

    const [currentTournament, setTournament] = useState({});
    const [currentTournamentStatus, setTournamentStatus] = useState(1);
    const [courts, setCourts] = useState([]);
    const [matches, setMatches] = useState([]);
    const [players, setPlayers] = useState([]);
    const [seeOldMatches, setSeeOldMatches] = useState(false);
    const [seeScores, setSeeScores] = useState(false);
    const [seeUpdateForm, setSeeUpdateForm] = useState(false);
    const [seePlayers, setSeePlayers] = useState(false);
    const courtsDisplay = courts.map((court) =>
        <CourtDisplay key={court.id} court = {court} tournament_id={tournament} players={players}/>
    );
    const nextMatchesDisplay = matches
        .filter((match) => !match.ended)
        .map((match) =>
        <MatchDisplay key={match.id} match = {match} players = {players} courts={courts} tournament_id={tournament} />
    );
    const oldMatchesDisplay = matches
        .filter((match) => match.ended)
        .map((match) =>
        <MatchDisplay key={match.id} match = {match} players = {players} />
    );
    const [delay, setDelay] = useState(0);

    const numberOfMatchesByPlayer = matches.reduce((acc, match) => {
        match.player1_id in acc ? acc[match.player1_id]++ : acc[match.player1_id] = 1;
        match.player2_id in acc ? acc[match.player2_id]++ : acc[match.player2_id] = 1;
        match.player3_id in acc ? acc[match.player3_id]++ : acc[match.player3_id] = 1;
        match.player4_id in acc ? acc[match.player4_id]++ : acc[match.player4_id] = 1;
        return acc;
    }, {});
    const maxNumberOfMatchesByPlayer = Object.values(numberOfMatchesByPlayer).reduce((acc, value) => Math.max(acc, value), 0);
    const minNumberOfMatchesByPlayer = Object.values(numberOfMatchesByPlayer).reduce((acc, value) => Math.min(acc, value), 1000);
    const playerWithMaxNumberOfMatches = players.find((player) => numberOfMatchesByPlayer[player.id] === maxNumberOfMatchesByPlayer);
    const playerWithMinNumberOfMatches = players.find((player) => numberOfMatchesByPlayer[player.id] === minNumberOfMatchesByPlayer);

    let simpleHommeScoresDisplay = <ScoreDisplay matches = {matches.filter((match) => match.ended && match.simple && !match.genre)} players = {players} tournament={currentTournament} />;
    let simpleFemmeScoresDisplay = <ScoreDisplay matches = {matches.filter((match) => match.ended && match.simple && match.genre)} players = {players} tournament={currentTournament} />;
    let doubleHommeScoresDisplay = <ScoreDisplay matches = {matches.filter((match) => match.ended && !match.simple && !match.genre)} players = {players} tournament={currentTournament} />;
    let doubleFemmeScoresDisplay = <ScoreDisplay matches = {matches.filter((match) => match.ended && !match.simple && match.genre)} players = {players} tournament={currentTournament} />;
    let doubleMixteScoresDisplay = <ScoreDisplay matches = {matches.filter((match) => match.ended && match.mixed)} players = {players} tournament={currentTournament} />;
    const playersDisplay = players.map((player) =>
        <PlayerDisplay key={player.id} player = {player} tournament={tournament} />
    );

    function checkTournamentStatus(date, time_start, time_end) {
        // if date is in the future return 1
        // if date is in the past return -1
        // else return 0

        let today = new Date();

        let tournament_date = new Date(date);
        tournament_date.setHours(time_start.split(":")[0], time_start.split(":")[1], time_start.split(":")[2], 0);

        if (tournament_date > today) {
            return 1;
        }
        else {
            // check if current time is between time_start and time_end (time string format: "10:00:00")
            let tournament_time_end = new Date(date);
            tournament_time_end.setHours(time_end.split(":")[0], time_end.split(":")[1], time_end.split(":")[2], 0);
            if (today >= tournament_date && today <= tournament_time_end) {
                return 0;
            }
            // if at least one match is not ended, return 0
            else if (matches.filter((match) => !match.ended).length > 0) {
                return 0;
            }
            else {
                return -1;
            }
        }
    }

    async function generateTournamentGames() {
        
        if (currentTournamentStatus === 0) {
            alert("Le tournoi est en cours, vous ne pouvez pas (re)générer les matchs !");
            return;
        } else if (currentTournamentStatus === -1) {
            alert("Le tournoi est terminé, vous ne pouvez pas (re)générer les matchs !");
            return;
        }

        if (!window.confirm("Êtes-vous sûr de vouloir (re)générer les matchs ?")) {
            return;
        }

        try {
            const resp = await tournament_api.generateTournamentGamesByTournamentId(tournament);
            if (resp === 204) {
                alert("Les matchs ont été (re)générés !");
                window.location.reload();
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        async function fetchData() {
            const resp = await tournament_api.getTournamentById(tournament);
            setTournament(resp);
            const respC = await tournament_api.getCourtsByTournamentId(tournament);
            setCourts(respC);
            const respM = await tournament_api.getMatchesByTournamentId(tournament);
            setMatches(respM);
            const respP = await tournament_api.getPlayersByTournamentId(tournament);
            setPlayers(respP);

            const status = checkTournamentStatus(resp.date, resp.time_start, resp.time_end);
            setTournamentStatus(status);
            if (status === -1) {
                setSeeOldMatches(true);
                setSeeScores(true);
            }

            if (status === 0) {
                // return total delay of tournament in minutes
                // for each match, get delay
                let delay = 0;
                respM.forEach((respM) => {

                    const time_start = new Date();
                    time_start.setHours(respM.time_start.split(":")[0], respM.time_start.split(":")[1], respM.time_start.split(":")[2], 0);

                    let starded = false;
                    const time_effective = new Date();
                    if (respM.effective_time) {
                        starded = true;
                        time_effective.setHours(parseInt(respM.effective_time.split(":")[0]) + 2, respM.effective_time.split(":")[1], respM.effective_time.split(":")[2], 0);
                    }

                    // if match is started and not ended, add delay
                    if (starded && !respM.ended) {
                        delay += time_start - time_effective;
                    } else if (!starded && time_start < new Date()) {
                        delay += time_start - new Date();
                    }
                });
                setDelay(Math.round(delay / 60000));

                setTimeout(async () => {
                    await fetchData()
                }, 10000);
            }
        }
        fetchData();
    }, []);

    return (

        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{"ASFlavigny - " + currentTournament.name}</title>
                {currentTournament.name &&
                    <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                }
                {currentTournament.name &&
                    <script type="text/javascript">
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'G-GLK66L6C6D');
                        `}
                    </script>
                }
            </Helmet>

            <div className="my-3 text-center">
                <h1 className="text-2xl font-bold font-outfit">{ currentTournament.name ? currentTournament.name.toUpperCase() : ""}</h1>
                {(currentTournamentStatus === 0) &&
                    <span className="whitespace-nowrap rounded-full bg-green-100 px-2.5 py-0.5 text-sm text-green-700">Tournoi en cours</span>
                }
                {(currentTournamentStatus === 1) &&
                    <span className="whitespace-nowrap rounded-full bg-orange-100 px-2.5 py-0.5 text-sm text-orange-700">Inscriptions en cours</span>
                }
                {(currentTournamentStatus === -1) &&
                    <span className="whitespace-nowrap rounded-full bg-red-100 px-2.5 py-0.5 text-sm text-red-700">Tournoi terminé</span>
                }
            </div>

            {(currentTournamentStatus === 0) && <div className={"text-center mb-3"}>
                <div>
                    {delay > 0 && <div className="text-center">
                        {Math.abs(delay)} minutes d'avance
                    </div>}
                    {delay < 0 && <div className="text-center">
                        {Math.abs(delay)} minutes de retard
                    </div>}
                    {delay === 0 && <div className="text-center">
                        A l'heure
                    </div>}
                </div>
            </div>}

            {(currentTournamentStatus === 1) &&
                <div class="w-full md:w-96 mx-auto">
                    <TournamentForm tournament={currentTournament}/>
                </div>
            }
            {(currentTournamentStatus !== 1) &&
                <div className="space-y-7">
                    {(currentTournamentStatus !== -1 && !seeScores) && <div>
                        <div className="flex justify-between">
                            <h2 className="text-xl mb-2">Les terrains</h2>
                            <h2 onClick={() => setSeeScores(true)}  className="text-xl mb-2 text-gray-400 cursor-pointer text-right">Les scores</h2>
                        </div>
                        <div className="flex space-x-3 overflow-x-auto">
                            { courtsDisplay }
                        </div>
                    </div>}
                    {(seeScores) && <div>
                        <div className="flex justify-between">
                            {(currentTournamentStatus !== -1) && <h2 onClick={() => setSeeScores(false)}  className="text-xl text-gray-400 cursor-pointer mb-2">Les terrains</h2>}
                            <h2 className="text-xl mb-2 text-right">Les scores</h2>
                        </div>
                        <div className="space-y-3 overflow-x-auto">
                            {simpleHommeScoresDisplay}
                            {simpleFemmeScoresDisplay}
                            {doubleHommeScoresDisplay}
                            {doubleFemmeScoresDisplay}
                            {doubleMixteScoresDisplay}
                        </div>
                    </div>}
                    {(!seeOldMatches) && <div>
                        <div className="flex justify-between">
                            <h2 className="text-xl mb-2">Les prochains matchs</h2>
                            <h2 onClick={() => setSeeOldMatches(true)} className="text-xl mb-2 text-gray-400 cursor-pointer text-right">Les anciens matchs</h2>
                        </div>
                            <div className="space-y-5">
                                { nextMatchesDisplay }
                            </div>
                        </div>
                    }
                    {(seeOldMatches) && <div>
                        <div className="flex justify-between">
                            {(currentTournamentStatus !== -1) && <h2 onClick={() => setSeeOldMatches(false)} className="text-xl mb-2 text-gray-400 cursor-pointer">Les prochains matchs</h2>}
                            <h2 className="text-xl mb-2 text-right">Les anciens matchs</h2>
                        </div>
                        <div className="space-y-5">
                            { oldMatchesDisplay }
                        </div>
                    </div>
                    }
                </div>
            }

            {(localStorage.getItem('username') !== null) && <div className="bg-red-200 w-full rounded p-2 mt-10 space-y-5">
                <h1 className="font-bold text-xl">Zone d'administration</h1>
                <div>
                    <h2 className="text-xl mb-1">Gestion de l'échéancier</h2>
                    <div className="flex space-x-3">
                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => generateTournamentGames()}>(Re)générer les matchs</button>
                        <button className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded" onClick={() => setTournamentStatus(currentTournamentStatus ? 0 : 1)}>Voir l'échéancier</button>
                    </div>
                </div>
                <div>
                    <h2 className="text-xl mb-1">Gestion du tournoi</h2>
                    <div className="flex space-x-3">
                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => setSeeUpdateForm(!seeUpdateForm)}>Modifier le tournoi</button>
                        <button className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded" onClick={() => setSeePlayers(!seePlayers)}>Voir les joueurs ({players.length})</button>
                    </div>
                </div>
                {(matches.length > 0) &&
                    <div>
                        <h2 className="text-xl mb-1">Statistiques du tournoi</h2>
                        <div className="flex space-x-3">
                            <div className="bg-gray-500 text-white font-bold py-2 px-4 rounded">{players.length} joueurs</div>
                            <div className="bg-gray-500 text-white font-bold py-2 px-4 rounded">{matches.length} matchs</div>
                            <div className="bg-gray-500 text-white font-bold py-2 px-4 rounded">{courts.length} terrains</div>
                            {(playerWithMaxNumberOfMatches && playerWithMinNumberOfMatches) &&
                                <>
                                    <div className="bg-gray-500 text-white font-bold py-2 px-4 rounded">{maxNumberOfMatchesByPlayer} matchs maximum / j ({playerWithMaxNumberOfMatches.firstname + " " + playerWithMaxNumberOfMatches.lastname})</div>
                                    <div className="bg-gray-500 text-white font-bold py-2 px-4 rounded">{minNumberOfMatchesByPlayer} matchs minimum / j ({playerWithMinNumberOfMatches.firstname + " " + playerWithMinNumberOfMatches.lastname})</div>
                                </>
                            }   
                        </div>
                    </div>
                }
                {(seeUpdateForm) && <div>
                    <h2 className="text-xl mb-1">Modification du tournoi</h2>
                    <div>
                        <TournamentUpdateForm tournament={currentTournament}/>
                    </div>
                </div>}
                {(seePlayers) && <div>
                    <h2 className="text-xl mb-1">Liste des joueurs</h2>
                    <div className="space-y-3">
                        { playersDisplay }
                    </div>
                </div>}
            </div>}
        </div>
    )
}

export default Tournament;