import {useInput} from "../../hooks/input-hook";
import React, {useEffect} from 'react';
import {tournament_api} from "../../api/tournament_api";

function TournamentUpdateForm({tournament}) {

    const { value:name, bind:bindName, reset:resetName } = useInput('');
    const { value:time_start, bind:bindTimeStart, reset:resetTimeStart } = useInput('');
    const { value:time_end, bind:bindTimeEnd, reset:resetTimeEnd } = useInput('');
    const { value:nb_courts, bind:bindNbCourts, reset:resetNbCourts } = useInput(0);
    const { value:nb_players, bind:bindNbPlayers, reset:resetNbPlayers } = useInput(0);
    const { value:time_break_minute, bind:bindTimeBreakMinute, reset:resetTimeBreakMinute } = useInput(0);
    const { value:time_match_minute, bind:bindTimeMatchMinute, reset:resetTimeMatchMinute } = useInput(0);
    const { value:password, bind:bindPassword, reset:resetPassword } = useInput('');

    useEffect(() => {

        if (tournament.id !== undefined) {
            resetName(tournament.name);
            resetTimeStart(tournament.time_start);
            resetTimeEnd(tournament.time_end);
            resetNbCourts(tournament.nb_court);
            resetNbPlayers(tournament.nb_player);
            resetTimeBreakMinute(tournament.time_break_minute);
            resetTimeMatchMinute(tournament.time_match_minute);
            resetPassword(tournament.password);
        }
    }, [tournament]);

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        const data = {
            name: name,
            time_start: time_start,
            time_end: time_end,
            nb_court: nb_courts,
            nb_player: nb_players,
            time_break_minute: time_break_minute,
            time_match_minute: time_match_minute,
            password: password,
        }

        try {
            let result = await tournament_api.updateTournament(tournament.id, data);
            if (result === 204) {
                alert("Tournoi mis à jour !");
            } else {
                alert("Erreur lors de la modification du tournoi !");
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="w-full">
            <form className="space-y-3 mt-2 w-full" onSubmit={handleSubmit} >
                <div>
                    <label htmlFor="name" className="text-sm font-medium text-gray-700">Nom du tournoi</label>
                    <input type="text" name="name" id="name" placeholder={"Nom du tournoi"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindName} />
                </div>

                <div>
                    <label htmlFor="time_start" className="text-sm font-medium text-gray-700">Heure de début du tournoi</label>
                    <input type="time" name="time_start" id="time_start" placeholder={"Heure de début du tournoi"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindTimeStart} />
                </div>

                <div>
                    <label htmlFor="time_end" className="text-sm font-medium text-gray-700">Heure de fin du tournoi</label>
                    <input type="time" name="time_end" id="time_end" placeholder={"Heure de fin du tournoi"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindTimeEnd} />
                </div>

                <div>
                    <label htmlFor="nb_courts" className="text-sm font-medium text-gray-700">Nombre de terrains</label>
                    <input type="number" name="nb_courts" id="nb_courts" placeholder={"Nombre de terrains"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindNbCourts} />
                </div>

                <div>
                    <label htmlFor="nb_players" className="text-sm font-medium text-gray-700">Nombre de joueurs</label>
                    <input type="number" name="nb_players" id="nb_players" placeholder={"Nombre de joueurs"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindNbPlayers} />
                </div>

                <div>
                    <label htmlFor="time_break_minute" className="text-sm font-medium text-gray-700">Temps de pause (minutes)</label>
                    <input type="number" name="time_break_minute" id="time_break_minute" placeholder={"Temps de pause (minutes)"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindTimeBreakMinute} />
                </div>

                <div>
                    <label htmlFor="time_match_minute" className="text-sm font-medium text-gray-700">Temps de match (minutes)</label>
                    <input type="number" name="time_match_minute" id="time_match_minute" placeholder={"Temps de match (minutes)"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindTimeMatchMinute} />
                </div>

                <div>
                    <label htmlFor="password" className="text-sm font-medium text-gray-700">Mot de passe</label>
                    <input type="text" name="password" id="password" placeholder={"Mot de passe"}
                           className="w-full rounded-lg border border-gray-200 p-4 pe-12 text-sm shadow-sm" {...bindPassword} />
                </div>

                <button
                    className="w-full inline-block rounded border border-blue-500 bg-blue-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-indigo-500"
                >
                    Mettre à jour
                </button>
            </form>
        </div>
    );
}

export default TournamentUpdateForm;