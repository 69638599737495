import React, {useEffect, useRef, useState} from 'react';
import {contact_api} from "../api/contact_api";
import {useInput} from "../hooks/input-hook";
import Message from "../components/contact/Message";
import Info from "../components/global/Info";
import {Helmet} from "react-helmet";

function Contact () {

    const { value:nom, bind:bindNom, reset:resetNom } = useInput('');
    const { value:subject, bind:bindSubject, reset:resetSubject } = useInput('');
    const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');
    const { value:message, bind:bindMessage, reset:resetMessage } = useInput('');

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        try {
            let result = await contact_api.insertMessage(nom, email, subject, message);
            if (result === 204) {
                resetNom();
                resetSubject();
                resetEmail();
                resetMessage();
                infoRef.current.seeInfo("green", "Message envoyé !");
            } else {
                infoRef.current.seeInfo("red", "Erreur lors de l'envoi du message !");
            }
        } catch (e) {
            console.log(e);
        }
    }

    const [allcontacts, setContacts] = useState([]);

    let contacts = allcontacts.map((contact) =>
        <Message key={contact.id} message={contact}/>
    );

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        async function fetchData() {
            if (localStorage.getItem('username') !== null) {
                const resp = await contact_api.getMessages();
                setContacts(resp);
            }
        }
        fetchData();
    }, []);

    const infoRef = useRef();

    return(
      <div className={"max-w-sm my-0 mx-auto"}>
          <Helmet>
              <meta charSet="utf-8" />
              <title>ASFlavigny - Contact</title>
              <meta name="description" content="Besoin d'informations ? Contactez-nous maintenant !" />
              <link rel="canonical" href="/contact"/>
              <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
              <script type="text/javascript">
                  {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-GLK66L6C6D');
                    `}
              </script>
          </Helmet>

          {localStorage.getItem('username') !== null &&
              <div>
                  <div className={"font-bold text-2xl"}>
                      Reception du formulaire de contact
                  </div>
                  <div className={"mt-5 space-y-10"}>
                      {contacts}
                  </div>
              </div>
          }
          {localStorage.getItem('username') === null &&
              <div>
                  <form onSubmit={handleSubmit}>
                      <div className="flex flex-col space-y-4">
                          <div className="flex flex-col space-y-4">
                              <label htmlFor="name" className="text-gray-700 font-outfit">Nom</label>
                              <input type="text" required maxLength="100" className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" placeholder="Nom" {...bindNom} />
                          </div>
                          <div className="flex flex-col space-y-4">
                              <label htmlFor="name" className="text-gray-700 font-outfit">Sujet</label>
                              <input type="text" required maxLength="100" className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" placeholder="Sujet" {...bindSubject} />
                          </div>
                          <div className="flex flex-col space-y-4">
                              <label htmlFor="email" className="text-gray-700 font-outfit">Email</label>
                              <input type="email" required maxLength="100" className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" placeholder="email@domaine.com" {...bindEmail} />
                          </div>
                          <div className="flex flex-col space-y-4">
                              <label htmlFor="message" className="text-gray-700 font-outfit">Message</label>
                              <textarea required className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows="10" cols="50" id="message" placeholder="Votre super message..." {...bindMessage} />
                          </div>
                          <div className="flex flex-col space-y-4">
                              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold font-outfit py-2 px-4 rounded focus:outline-none focus:shadow-outline w-44" type="submit">
                                  ENVOYER
                              </button>
                          </div>
                      </div>
                  </form>
                  <div className={"mt-5"}>
                      <Info ref={infoRef}/>
                  </div>
              </div>
          }
      </div>
    );
}

export default Contact;