import React, {useEffect, useRef, useState} from 'react';
import {announcements_api} from "../../api/announcements_api";
import PopUp from "./PopUp";
import AnnouncementForm from "../form/AnnouncementForm";

// Component (here it's a view)
function Announcement(props) {

    const [announcement, setA] = useState({}); // [announcements, setAnnouncements
    const [show, setShow] = useState(true);

    const popUpRef = useRef();

    // Function called on load of the component...
    useEffect(async () => {

        const resp = await announcements_api.getAnnouncements();
        setA(resp);
        const s = localStorage.getItem('announcement') === null || localStorage.getItem('announcement') !== resp.content;
        setShow(s);
    }, []);

    const close = () => {
        localStorage.setItem('announcement', announcement.content);
        setShow(false);
    }

    return (
        <div>
            <PopUp ref={popUpRef} children={<AnnouncementForm/>}/>
            {((show && announcement.content !== undefined && announcement.content.length > 0) || localStorage.getItem('username') !== null) && (
            <div className="z-20 relative bg-green-500 py-3 px-9 text-white w-full">
                <div className={"relative flex items-center justify-between gap-4 max-w-lg my-0 mx-auto w-full"}>
                    <p className="text-sm font-medium space-x-1">
                    <span>
                        {announcement.content}
                    </span>
                        {show && announcement.link_text !== undefined && announcement.link_text.length > 0 && <a href={announcement.link} className="inline-block underline">{announcement.link_text}</a> }
                        {localStorage.getItem('username') !== null &&
                            <span onClick={() => popUpRef.current.seePopup()} className="bg-red-500 p-1 rounded-full cursor-pointer">
                            MODIFIER
                        </span>
                        }
                    </p>

                    <button
                        aria-label="Dismiss"
                        className="shrink-0 rounded-lg bg-black/10 p-1 transition hover:bg-black/20"
                        onClick={close}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            )}
        </div>
    )
}

export default Announcement;