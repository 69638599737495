import React, {useEffect, useState} from 'react';
import {event_api} from "../../api/event_api";

function Calendar() {

    async function textToColor(text) {
        // Calcule le hash SHA-256 de la chaîne de texte
        const hash = await sha256(text);

        // Extrait les 6 premiers caractères du hash
        const shortHash = hash.substring(0, 6);

        // Convertit le hash en une couleur hexadécimale
        const color = `#${shortHash}`;

        return color;
    }

    // Fonction de hachage SHA-256
    function sha256(text) {
        const encoder = new TextEncoder();
        const data = encoder.encode(text);
        return crypto.subtle.digest("SHA-256", data).then(bufferToHex);
    }

    // Fonction utilitaire pour convertir un tableau d'octets en une chaîne hexadécimale
    function bufferToHex(buffer) {
        const view = new DataView(buffer);
        let hex = '';
        for (let i = 0; i < view.byteLength; i += 4) {
            const value = view.getUint32(i);
            hex += value.toString(16).padStart(8, '0');
        }
        return hex;
    }

    const [allevents, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    // Create dictionary of events by date
    const eventsByDate = {};
    allevents.forEach((event) => {
        const date = new Date(event.date).toLocaleDateString();
        if (eventsByDate[date] == null) eventsByDate[date] = [];
        eventsByDate[date].push(event);
    });

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    async function fetchData(date, date2) {

        const resp = await event_api.getEventsFromDate(date.toDateString(), date2.toDateString());
        // set color to event
        for (let i = 0; i < resp.length; i++) {
            resp[i].color = await textToColor(resp[i].type);
        }
        setEvents(resp);
    }

    async function fetchBefore() {

        const currentM = (currentMonth - 1) % 12
        const currentY = currentM === 0 ? currentYear - 1 : currentYear
        setCurrentMonth(currentM);
        setCurrentYear(currentY);

        const firstDayOfMonth = new Date(currentY, currentM, 1)
        const lastDayOfMonth = new Date(currentY, (currentM + 1) % 12, 0)

        await fetchData(firstDayOfMonth, lastDayOfMonth);
    }

    async function fetchAfter() {

        const currentM = (currentMonth + 1) % 12;
        const currentY = currentM === 0 ? currentYear + 1 : currentYear
        setCurrentMonth(currentM);
        setCurrentYear(currentY);

        const firstDayOfMonth = new Date(currentY, currentM, 1)
        const lastDayOfMonth = new Date(currentY, (currentM + 1) % 12, 0)

        await fetchData(firstDayOfMonth, lastDayOfMonth);
    }

    // Function called on load of the component...
    useEffect(() => {

        // Obtenez la date actuelle
        const currentDate = new Date();

        // Obtenez le mois et l'année actuels
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        // Obtenez le premier jour du mois et le nombre de jours dans le mois
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1)
        const lastDayOfMonth = new Date(currentYear, (currentMonth + 1) % 12, 0)

        fetchData(firstDayOfMonth, lastDayOfMonth);
    }, []);

    // Obtenez le premier jour du mois et le nombre de jours dans le mois
    const firstDayOfMonth = (new Date(currentYear, currentMonth, 1).getDay() || 7) - 1;
    const lastDayOfMonth = new Date(currentYear, (currentMonth + 1) % 12, 0).getDate();

    // Créez un tableau de noms de jours de la semaine
    const daysOfWeek = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];

    // Créez un tableau pour stocker les jours du mois
    const calendarDays = [];

    // Remplissez les jours du mois précédent pour compléter la première semaine
    for (let i = 0; i < firstDayOfMonth; i++) {
        calendarDays.push(<div key={`empty-${i}`} className="text-gray-400"></div>);
    }

    // Remplissez les jours du mois actuel
    for (let day = 1; day <= lastDayOfMonth; day++) {
        const date = new Date(currentYear, currentMonth, day).toLocaleDateString();
        if (eventsByDate[date] != null) {
            calendarDays.push(
                <div title={eventsByDate[date][0].name} key={day} className={"text-black cursor-pointer" + (day === new Date().getDate() ? " bg-flavigny/80 font-bold" : "")} onClick={() => setSelectedEvent(eventsByDate[date])} style={{backgroundColor: eventsByDate[date][0].color}}> {day} </div>
            );
        } else {
            calendarDays.push(
                day === new Date().getDate() &&
                <div key={day} className={"text-red-500 " + (date === new Date().toLocaleDateString() ? " font-bold" : "")}>
                    {day}
                </div>
                || <div key={day} className="text-black">{day}</div>
            );
        }
    }

    // Affichez les jours de la semaine en haut du calendrier
    const daysOfWeekElements = daysOfWeek.map((day) => (
        <div>
            {(new Date().getDay() || 7) - 1 === daysOfWeek.indexOf(day) && 
                <div key={day}>
                    <div className="hidden sm:block text-red-500 font-bold">{day.substring(0,1)}</div>
                    <div className="block sm:hidden text-red-500 font-bold">{day}</div>
                </div>
            }
            {(new Date().getDay() || 7) - 1 !== daysOfWeek.indexOf(day) && 
                <div key={day}>
                    <div className="hidden sm:block text-gray-400 font-bold">{day.substring(0,1)}</div>
                    <div className="block sm:hidden text-black font-bold">{day}</div>
                </div>
            }
        </div>
    ));

    const month = new Intl.DateTimeFormat('fr-FR', { month: 'long', year: 'numeric' }).format(new Date(currentYear, currentMonth, 1));

    return (
        <div>
            {selectedEvent && <div className="grid grid-cols-1 gap-2">
                {selectedEvent.map((event, index) => (
                <div key={index} className={"p-4 bg-gray-100"}>
                    <div className={"flex justify-between mb-4"}>
                        <div className="whitespace-nowrap rounded-full px-2.5 py-0.5 text-sm" style={{backgroundColor: event.color}}>
                            {event.type}
                        </div>
                        <div onClick={() => setSelectedEvent(null)} className="cursor-pointer text-3xl -mb-3 -mt-3">×</div>
                    </div>
                    <a href={event.link} target="_blank" rel="noreferrer">
                        <p className="text-xl font-bold font-outfit">
                            {event.name}
                        </p>
                        <div className="text-sm mt-2 mb-4 text-justify whitespace-pre-line">
                            <p dangerouslySetInnerHTML={{__html: event.description}}></p>
                        </div>
                        <p className={"text-sm text-blue-500 hover:underline"}>En savoir plus</p>
                        <div className="text-sm font-outfit font-light">
                            {new Date(event.date).toLocaleDateString()}
                        </div>
                    </a>
                </div>))}
            </div> || <div className="p-4 bg-gray-100">
                <div className={"flex justify-between"}>
                    <div className="text-xl font-bold mb-4">
                        {month[0].toUpperCase() + month.slice(1)}
                    </div>
                    <div>
                        <button onClick={() => {
                            fetchBefore();
                        }} className="text-2xl mr-2">←</button>
                        <button onClick={() => {
                            fetchAfter();
                        }} className="text-2xl">→</button>
                    </div>
                </div>
                <div className="grid grid-cols-7 gap-2 text-center">
                    {daysOfWeekElements}
                    {calendarDays}
                </div>
            </div>}
        </div>
    );
}

export default Calendar;