import React, {useRef, useEffect, useState} from 'react';
import Mobile from "../menu/Mobile";

// Component (here it's a view)
function Partner(props) {
    return (
        <div className="mx-9 pt-10">
            <div className="max-w-lg my-0 mx-auto">
            <h2 className={"text-2xl sm:text-4xl font-bold pb-5 text-center font-outfit"}>
                NOS PARTENAIRES
            </h2>
            <div className="mb-10">
                {/* <div className="space-y-5 sm:flex sm:justify-center sm:space-x-8 sm:space-y-0">
                    <div className="flex justify-center md:block">
                        <a href="https://www.mairie-flavigny-sur-moselle.fr/" target="_blank">
                            <img src="/img/partners/flavigny.png" alt="Logo de la ville de Flavigny-sur-Moselle" className="h-24"/>
                        </a>
                    </div>
                    <div className="flex justify-center sm:block">
                        <a href="https://www.elitecredit.fr/" target="_blank">
                            <img src="/img/partners/elitecredit.png" alt="Logo d'Elite Credit" className="h-24"/>
                        </a>
                    </div>
                    <div className="flex justify-center sm:block">
                        <a href="https://www.badminton54.com/" target="_blank">
                            <img src="/img/partners/cd54.jpeg" alt="Logo du CODEP 54 de Badminton" className="h-24"/>
                        </a>
                    </div>
                    <div className="flex justify-center sm:block">
                        <a href="https://www.ffbad.org/" target="_blank">
                            <img src="/img/partners/ffbad.svg" alt="Logo de la Fédération Française de Badminton" className="h-24"/>
                        </a>
                    </div>
                    <div className="flex justify-center sm:block">
                        <a href="https://www.lardesports.com" target="_blank">
                            <img src="/img/partners/lardesports.png" alt="Logo de Lardesports" className="h-24"/>
                        </a>
                    </div>
                </div> */}
                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 place-content-center">
                    <div className="flex justify-center">
                        <a href="https://www.mairie-flavigny-sur-moselle.fr/" target="_blank">
                            <img src="/img/partners/flavigny.png" alt="Logo de la ville de Flavigny-sur-Moselle" className="h-24"/>
                        </a>
                    </div>
                    <div className="flex justify-center">
                        <a href="https://www.elitecredit.fr/" target="_blank">
                            <img src="/img/partners/elitecredit.png" alt="Logo d'Elite Credit" className="h-24"/>
                        </a>
                    </div>
                    <div className="flex justify-center">
                        <a href="https://www.badminton54.com/" target="_blank">
                            <img src="/img/partners/cd54.jpeg" alt="Logo du CODEP 54 de Badminton" className="h-24"/>
                        </a>
                    </div>
                    <div className="flex justify-center">
                        <a href="https://www.lardesports.com" target="_blank">
                            <img src="/img/partners/lardesports.png" alt="Logo de Lardesports" className="h-24"/>
                        </a>
                    </div>
                    <div className="flex justify-center">
                        <a href="https://www.ffbad.org/" target="_blank">
                            <img src="/img/partners/ffbad.svg" alt="Logo de la Fédération Française de Badminton" className="h-24"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Partner;