import React, {useEffect, useState} from 'react';

// Component (here it's a view)
function Header(props) {

    return (
        <div>
            <div style={{backgroundColor: props.color}}>
                {props.children}
            </div>
            <div className="border-b border-black"></div>
        </div>
    )
}

export default Header;